var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        width: _vm.modalWidth,
        visible: _vm.visible,
        footer: null,
        cancelText: "关闭",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "div",
        { staticClass: "marginCss" },
        [
          _c(
            "a-table",
            {
              ref: "table",
              attrs: {
                size: "small",
                bordered: "",
                rowKey: "id",
                columns: _vm.columns,
                dataSource: _vm.dataSource,
                rowClassName: _vm.setdataCss,
                loading: _vm.loading,
                scroll: { y: 700 },
                pagination: false,
              },
              scopedSlots: _vm._u([
                {
                  key: "avatarslot",
                  fn: function (text, record) {
                    return [
                      _c("div", { staticClass: "anty-img-wrap" }, [
                        _c("img", {
                          attrs: { src: _vm.getAvatarView(record) },
                        }),
                      ]),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "span",
                {
                  attrs: { slot: "dataVersionTitle1" },
                  slot: "dataVersionTitle1",
                },
                [
                  _c("a-icon", { attrs: { type: "smile-o" } }),
                  _vm._v(" 版本:" + _vm._s(_vm.dataVersion1Num)),
                ],
                1
              ),
              _c(
                "span",
                {
                  attrs: { slot: "dataVersionTitle2" },
                  slot: "dataVersionTitle2",
                },
                [
                  _c("a-icon", { attrs: { type: "smile-o" } }),
                  _vm._v(" 版本:" + _vm._s(_vm.dataVersion2Num)),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }